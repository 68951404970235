
export const Terms = {
    qrtype: {
        adduser: 'adduser',
        device: 'device',
        devicebox: 'devicebox',
        meshandgo: 'meshandgo'
    },
    branding: {
        powermesh: 'powermesh',
        lucci: 'lucci',
        trader: 'trader',
        steelline: 'steelline'
    },
    deviceType: {
        '1G_10AX': '1G_10AX',
        'DIM': 'DIM',
        'FAN': 'FAN',
        'GPO(V1)': 'GPO(V1)',
        'GPO(V2)': 'GPO(V2)',
        'MDS_2G': 'MDS_2G',
        'MDS_4G': 'MDS_4G',
        'MPS_1GH': 'MPS_1GH',
        'MPS_1GV': 'MPS_1GV',
        'MPS_2GH': 'MPS_2GH',
        'MPS_2GV': 'MPS_2GV',
        'MPS_3GV': 'MPS_3GV',
        'MPS_4GH': 'MPS_4GH',
        'MPS_4GV': 'MPS_4GV',
        'UGDC': 'UGDC',
        'ZCC': 'ZCC',
        '2G_BC': '2G_BC',
        '4G_BC': '4G_BC',

        'SEN_2G_GPO': 'SEN_2G_GPO',
        'SEN_1G_DIM': 'SEN_1G_DIM',
        'SEN_1G_DIM_1G_SW': 'SEN_1G_DIM_1G_SW',
        'SEN_2G_DIM_2G_SW': 'SEN_2G_DIM_2G_SW',
        'SEN_2G_DIM_L_2G_BL': 'SEN_2G_DIM_L_2G_BL',
        'SEN_2G_DIM_2G_SW_2G_BL': 'SEN_2G_DIM_2G_SW_2G_BL',
        'SEN_1G_SW': 'SEN_1G_SW',
        'SEN_2G_SW': 'SEN_2G_SW',
        'SEN_4G_SW': 'SEN_4G_SW',
        'SEN_6G_SW': 'SEN_6G_SW',
        'SEN_2G_BC': 'SEN_2G_BC',
        'SEN_4G_BC': 'SEN_4G_BC',

        'SNAS_2G_GPO': 'SNAS_2G_GPO',
        'SNAS_1G_DIM_2G_SW': 'SNAS_1G_DIM_2G_SW',
        'SNAS_2G_DIM_1G_SW': 'SNAS_2G_DIM_1G_SW',
        'SNAS_3G_SW': 'SNAS_3G_SW',
        'SNAS_2G_BC_1G_SW': 'SNAS_2G_BC_1G_SW',
        'SMOKE_ALARM': 'SMOKE_ALARM',
        'ZEDC': 'ZEDC',

    }

}

export enum QRType {
    'adduser' = '20',
    'device' = '01',
    'devicebox' = '02',
    'meshandgo' = '10'
}

export enum Branding {
    'powermesh' = '00',
    'lucci' = '02',
    'trader' = '01',
    'steelline' = '03'
}

export type DeviceType = string;

export enum DeviceTypeEnum {
    '1G_10AX' = '0040',
    'DIM' = '0020',
    'MDS_2G' = '0022',
    'MDS_4G' = '0024',
    'FAN' = '0030',
    'GPO(V1)' = '0011',
    'GPO(V2)' = '0012',
    'MPS_1GH' = '0042',
    'MPS_1GV' = '0041',
    'MPS_2GH' = '0044',
    'MPS_2GV' = '0043',
    'MPS_3GV' = '0045',
    'MPS_4GH' = '0047',    
    'MPS_4GV' = '0046',
    'UGDC' = '0050',
    'ZCC' = '0002',

    '2G_BC' = '0060',
    '4G_BC' = '0061',

    'SEN_2G_GPO' = '1011',
    'SEN_1G_DIM' = '1021',
    'SEN_1G_DIM_1G_SW' = '1022',
    'SEN_2G_DIM_2G_SW' = '1024',
    'SEN_2G_DIM_L_2G_BL' = '1025',
    'SEN_2G_DIM_2G_SW_2G_BL' = '1026',
    'SEN_1G_SW' = '1041',
    'SEN_2G_SW' = '1042',
    'SEN_4G_SW' = '1044',
    'SEN_6G_SW' = '1046',
    'SEN_2G_BC' = '1060',
    'SEN_4G_BC' = '1061',

    'SNAS_2G_GPO' = '1111',
    'SNAS_1G_DIM_2G_SW' = '1123',
    'SNAS_2G_DIM_1G_SW' = '1124',
    'SNAS_3G_SW' = '1143',
    'SNAS_2G_BC_1G_SW' = '1160',

    'SMOKE_ALARM' = '0100',
    'ZEDC' = '0051'


}

export class QRDecodeMappings {


    public static getQRTypeFromCodestring(qrTypeStr: string): QRType | undefined {
        switch (qrTypeStr) {
            case QRType.adduser:
                return QRType.adduser;
            case QRType.device:
                return QRType.device;
            case QRType.devicebox:
                return QRType.devicebox;
            case QRType.meshandgo:
                return QRType.meshandgo;
            default:
                return;
        }
    }


    public static getQRTypeString(qrType?: QRType): string | undefined {
        if (qrType) {
            switch (qrType) {
                case QRType.adduser:
                    return Terms.qrtype.adduser;
                case QRType.device:
                    return Terms.qrtype.device;
                case QRType.devicebox:
                    return Terms.qrtype.devicebox;
                case QRType.meshandgo:
                    return Terms.qrtype.meshandgo;
                default:
                    return;
            }
        } else {
            return;
        }
    }

    public static getQRStringFromQRCodestring(qrTypeString: string): string | undefined {
        return QRDecodeMappings.getQRTypeString(QRDecodeMappings.getQRTypeFromCodestring(qrTypeString))
    }

    public static getBrandingStrFromCodeStr(brandingCodeStr: string): string | undefined {
        switch (brandingCodeStr) {
            case Branding.lucci:
                return Terms.branding.lucci;
            case Branding.powermesh:
                return Terms.branding.powermesh;
            case Branding.steelline:
                return Terms.branding.steelline;
            case Branding.trader:
                return Terms.branding.trader;
            default:
                return;
        }
    }

    public static getDeviceTypeStrFromCodeStr(deviceTypeCodeStr: string): string | undefined {
        const foundEnum = Object.entries(DeviceTypeEnum)
            .find(
                (enKey) => {
                    return enKey[1] === deviceTypeCodeStr
                }
            )

        if (foundEnum) {
            const deviceTerm = Object.keys(Terms.deviceType).find(dt => dt === foundEnum[0])
            return deviceTerm;
        }
    }

}